
import { defineComponent } from "vue";
import DeliveryChallanItemRow from "./DeliveryChallanItemRow.vue";

export default defineComponent({
  components: {
    DeliveryChallanItemRow,
  },
  props: {
    delivery_challan: {
      type: Number,
      required: true,
    },
    deliveryChallanItems: {
      type: Array,
      required: true,
    },
    onApiComplete: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.onApiComplete();
  },
});
