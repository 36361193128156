<template>
  <div>
    <button
      @click="printPage"
      type="button"
      class="btn mx-2 btn-lg btn-light btn-active-light-primary mx-2"
    >
      <span class="svg-icon svg-icon-3">
        <inline-svg src="media/icons/duotune/files/fil021.svg" />
      </span>
      Download Challan
    </button>
    <div
      v-show="false"
      v-if="partyData && challanData && challanItems.length > 0"
      ref="printSection"
    >
      <div class="container text-center">
        <div class="border-dark border mt-4 pt-3">
          <div class="align-items-baseline row">
            <div class="col-2">
              <img
                src="https://unnati.creations.buzzyevent.com/media/logos/logo.png" 
                width="100px"
              />
            </div>
            <div class="col-8">
              <h1 class="">Unnati Creations</h1>
              <p>All Types Of Commercial Designing & Laser Cutting Services</p>
            </div>
            <div class="col-2"></div>
          </div>
          <p class="border border-left-0 border-right-0 my-2 py-3 border-dark">
            Shop No. 7, Sanjeev Nagar, Near Shastri Nagar, Off LBS Road, Bhandup
            West (W), Mumbai- 400078
          </p>
          <h2 class="my-2">Delivery Challan</h2>
        </div>
        <table
          class="text-center table table-bordered table-row-bordered table-sm"
        >
          <tr>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
          </tr>
          <tr>
            <th colspan="4">Place Of Supply</th>
            <th colspan="6">Party Details</th>
            <th colspan="2">Challan No.</th>
            <th colspan="2">Dated</th>
          </tr>
          <tr>
            <td colspan="4">{{ challanData.place_of_supply }}</td>
            <td colspan="6">
              <h3>{{ partyData.companyName }}</h3>
            </td>
            <td colspan="2">{{ challanData.challan_number }}</td>
            <td colspan="2">
              {{ moment(challanData.date).format("YYYY-MM-DD") }}
            </td>
          </tr>
          <tr>
            <th colspan="1">Sr. No.</th>
            <th colspan="3">Design No.</th>
            <th colspan="3">Image</th>
            <th colspan="3">Design Description</th>
            <th scope="col">Quantity</th>
            <th scope="col">Rate</th>
            <th colspan="2">Amount</th>
          </tr>
          <tr v-for="(challanItem, index) in challanItems" :key="index">
            <td v-if="challanItem.design_data">{{ index + 1 }}</td>
            <td colspan="3" v-if="challanItem.design_data">
              {{ challanItem.design_data.code }}
            </td>
            <td colspan="3" v-if="challanItem.design_data">
              <img
                class="img-thumbnail"
                style="max-height: 120px"
                :src="challanItem.design_data.image"
              />
            </td>
            <td colspan="3" v-if="challanItem.design_data">
              {{ challanItem.design_data.type }}
            </td>
            <td v-if="challanItem.design_data">
              {{ challanItem.quantity }}
            </td>
            <td v-if="challanItem.design_data">
              &#8377; {{ challanItem.design_data.finalCost }}
            </td>
            <td colspan="2" v-if="challanItem.design_data">
              &#8377;
              {{ challanItem.quantity * challanItem.design_data.finalCost }}
            </td>
          </tr>
          <tr>
            <td colspan="10">Remark</td>
            <th colspan="2">Total</th>
            <th colspan="2">&#8377; {{ challanData.total_amt }}</th>
          </tr>
          <tr>
            <td colspan="4" rowspan="2">
              <div
                class="align-items-center d-flex justify-content-center"
                style="height: 100px"
              >
                <div class="m-auto">Rs. In Words:</div>
              </div>
            </td>
            <td colspan="6" rowspan="2" class="text-capitalize">
              <div
                class="align-items-center d-flex justify-content-center"
                style="height: 100px"
              >
                <div class="m-auto font-weight-bolder">
                  {{ numberToWords(challanData.grand_total) }} Only
                </div>
              </div>
            </td>
            <td colspan="2">Round Off</td>
            <td colspan="2">&#8377; {{ challanData.round_off_amt }}</td>
          </tr>

          <tr>
            <th colspan="2">Grand Total</th>
            <th colspan="2">&#8377; {{ challanData.grand_total }}</th>
          </tr>
          <tr class="text-left">
            <td></td>
            <td colspan="3">
              <div class="mb-4">
                <span>Sign</span>
              </div>
              <div class="pt-3">
                <span>Date</span>
              </div>
            </td>
            <td colspan="6"></td>
            <td colspan="4">
              <div class="mb-5 mt-0">
                <span>For Unnati Creations</span>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="6">Recived By</td>
            <td colspan="3">Deliverd By</td>
            <td colspan="4">Created By</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { getDeliveryChallanById } from "@/core/data/deliveryChallan";
import { getDeliveryChallanItems } from "@/core/data/deliveryChallanItem";
import { getPartyById } from "@/core/data/party";
import moment from "moment";
export default {
  props: {
    delivery_challan: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      challanData: null,
      challanItems: [],
      partyData: null,
    };
  },
  methods: {
    moment,
    async printPage() {
      await this.fetchChallanData();
      await this.fetchChallanItems();
      let printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Unnati-Creation-Challan-" +
          this.challanData.challan_number +
          "</title>"
      );
      printWindow.document.write(
        '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">'
      );
      printWindow.document.write(
        "<style>@page { size: auto;  margin: 0mm; }.table-bordered td,.table-bordered th {  border: 1px solid #000000 !important;}</style></head><body>"
      );
      printWindow.document.write(this.$refs.printSection.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
      };
    },

    async fetchChallanData() {
      this.challanData = await getDeliveryChallanById(this.delivery_challan);
      this.partyData = await getPartyById(this.challanData.party);
    },
    async fetchChallanItems() {
      const data = await getDeliveryChallanItems(1, {
        delivery_challan: this.delivery_challan,
      });
      this.challanItems = data.data;
    },
    numberToWords(n) {
      const units = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];
      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      if (n < 0) throw new Error("Negative numbers are not supported.");

      // Split the number into integer and decimal parts
      const [integerPart, decimalPart] = n.toString().split(".");

      let words = "";

      // Convert the integer part
      words += convertToWords(parseInt(integerPart));

      // Convert the decimal part, if it exists
      if (decimalPart) {
        // words += " point";
        for (let digit of decimalPart) {
          // words += " " + units[parseInt(digit)];
        }
      }

      return words;

      function convertToWords(num) {
        const numString = num.toString();
        if (num < 20) return units[num];
        if (numString.length === 2)
          return (
            tens[numString[0]] +
            (num % 10 !== 0 ? " " + units[numString[1]] : "")
          );
        if (numString.length === 3)
          return (
            units[numString[0]] +
            " hundred" +
            (num % 100 !== 0 ? " " + convertToWords(num % 100) : "")
          );
        if (numString.length === 4)
          return (
            convertToWords(numString.substr(0, 1)) +
            " thousand" +
            (num % 1000 !== 0 ? " " + convertToWords(num % 1000) : "")
          );
        if (numString.length === 5)
          return (
            convertToWords(numString.substr(0, 2)) +
            " thousand" +
            (num % 10000 !== 0 ? " " + convertToWords(num % 10000) : "")
          );
        if (numString.length === 6)
          return (
            convertToWords(numString.substr(0, 1)) +
            " lakh" +
            (num % 100000 !== 0 ? " " + convertToWords(num % 100000) : "")
          );

        throw new Error("This method only supports numbers up to 10 Lakhs.");
      }
    },
  },
  async mounted() {
    await this.fetchChallanData();
    await this.fetchChallanItems();
    console.log(this.challanItems);
  },
};
</script>
