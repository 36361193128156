import moment from "moment";
import ApiService from "../services/ApiService";
interface DeliveryChallanItem {
  id: number;
  delivery_challan: string;
  design: string;
  quantity: string;
  design_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const DeliveryChallanItems: Array<DeliveryChallanItem> = [];

const deleteDeliveryChallanItem = function (item: DeliveryChallanItem) {
  return ApiService.delete(`delivery-challan-item/${item.id}/`);
};

const addDeliveryChallanItem = function (item, file, id) {
  const formData = new FormData();
  delete item.image;
  const dateFieldKey: any = [];
  for (const key in item) {
    if (dateFieldKey.includes(key)) {
      formData.append(key, moment(item[key]).format("YYYY-MM-DD"));
    } else {
      formData.append(key, item[key]);
    }
  }
  if (file && file[0]) {
    formData.append("image", file[0]);
  }
  if (id) {
    return ApiService.put(`delivery-challan-item/${id}/`, formData);
  } else {
    return ApiService.post("delivery-challan-item/", formData);
  }
};

const exportDeliveryChallanItemData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/delivery-challan-item/?deliveryChallan_id=" + ids,
      "deliveryChallan-data"
    ).then((data) => res(data));
  });
};
const exportPDFDeliveryChallanItems = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile(
      "pdf/delivery-challan-item/?deliveryChallan_id=" + ids
    ).then((data) => res(data));
  });
};
const deliveryChallanItemObjClone = function () {
  return {
    id: 0,
    delivery_challan: "",
    design: "",
    quantity: "",
    created_by: "",
    design_data: {},
  };
};

const jsonToInterface = function (item: any) {
  const newItem: DeliveryChallanItem = deliveryChallanItemObjClone();
  newItem.id = item.id;
  newItem.delivery_challan = item.delivery_challan;
  newItem.design = item.design;
  newItem.design_data = item.design_data;
  newItem.quantity = item.quantity;
  newItem.created_by = item.created_by;
  return newItem;
};
const getDeliveryChallanItemById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`delivery-challan-item/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getDeliveryChallanItems = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      delivery_challan: searchData.delivery_challan || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(
      `delivery-challan-item/?${queryStr}`,
      cancelRequest
    )
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const getDeliveryChallanItemCuttingsCount = function (
  status = "",
  deliveryChallan_id = "",
  design_material_item_id = ""
) {
  return new Promise((res, rej) => {
    const queryObj = {
      deliveryChallan_id: deliveryChallan_id,
      design_material_item_id: design_material_item_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(
      `delivery-challan-item/count/?${queryStr}`,
      cancelRequest
    )
      .then(function (response) {
        res(response.data);
      })
      .catch((error) => rej(error));
  });
};
const jsonToInterfaceDeliveryChallanItem = jsonToInterface;
const getEmptyObjOfDeliveryChallanItem = deliveryChallanItemObjClone;
export {
  DeliveryChallanItem,
  getDeliveryChallanItems,
  deleteDeliveryChallanItem,
  addDeliveryChallanItem,
  getDeliveryChallanItemById,
  jsonToInterfaceDeliveryChallanItem,
  deliveryChallanItemObjClone,
  exportDeliveryChallanItemData,
  exportPDFDeliveryChallanItems,
  getEmptyObjOfDeliveryChallanItem,
  getDeliveryChallanItemCuttingsCount,
};

export default DeliveryChallanItems;
