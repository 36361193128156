import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DesignMultiselect = _resolveComponent("DesignMultiselect")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createVNode(_component_el_form_item, { prop: "design" }, {
        default: _withCtx(() => [
          (_ctx.designData)
            ? (_openBlock(), _createBlock(_component_DesignMultiselect, {
                key: _ctx.editableItem.id,
                isEdit: _ctx.isEditing,
                class: "form-select rounded-3",
                modelValue: _ctx.editableItem.design,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editableItem.design) = $event)),
                selectedValue: _ctx.designData
              }, null, 8, ["isEdit", "modelValue", "selectedValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_el_form_item, { prop: "quantity" }, {
        default: _withCtx(() => [
          (_ctx.isEditing)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                modelValue: _ctx.editableItem.quantity,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editableItem.quantity) = $event)),
                placeholder: ""
              }, null, 8, ["modelValue"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.item.quantity) + " X ₹ " + _toDisplayString(_ctx.item.design_data?.finalCost), 1))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("td", null, [
      (_ctx.item.quantity)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " ₹ " + _toDisplayString(parseInt(_ctx.item.quantity) * _ctx.item.design_data?.finalCost), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", null, [
      (!_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-lg btn-info mx-2",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.editItem && _ctx.editItem(...args)))
          }, " Edit "))
        : _createCommentVNode("", true),
      (_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-lg btn-primary mx-2",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.saveItem && _ctx.saveItem(...args)))
          }, " Save "))
        : _createCommentVNode("", true),
      (_ctx.isEditing && _ctx.editableItem?.id)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            type: "button",
            class: "btn btn-lg btn-secondary mx-2",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancelItem && _ctx.cancelItem(...args)))
          }, " Cancel "))
        : _createCommentVNode("", true),
      (!_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            type: "button",
            class: "btn btn-lg btn-danger mx-2",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args)))
          }, " Delete "))
        : _createCommentVNode("", true)
    ])
  ]))
}