
import { defineComponent, onMounted, ref } from "vue";
import {
  DeliveryChallanItem,
  deleteDeliveryChallanItem,
  addDeliveryChallanItem,
} from "@/core/data/deliveryChallanItem";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DesignMultiselect from "@/components/all-multiselect/design-multiselect.vue";
import { getDesignById } from "@/core/data/design";

export default defineComponent({
  components: {
    DesignMultiselect,
  },
  props: {
    item: {
      type: Object as () => DeliveryChallanItem,
      required: true,
    },
    onApiComplete: {
      type: Function,
      required: true,
    },
    delivery_challan: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const isEditing = ref(!props.item.id);
    const delivery_challan_id = ref(props.delivery_challan);
    const editableItem = ref({ ...props.item });
    const designData = ref({ code: "" });
    const editItem = () => {
      isEditing.value = true;
    };
    onMounted(() => {
      if (editableItem.value.design) {
        getDesignById(editableItem.value.design).then((data: any) => {
          designData.value = data;
          console.log("designData.value", designData.value);
        });
      }
    });
    const saveItem = () => {
      addDeliveryChallanItem(
        { ...editableItem.value, delivery_challan: props.delivery_challan },
        null,
        editableItem.value.id
      )
        .then(() => {
          isEditing.value = false;
          if (props.onApiComplete) {
            props.onApiComplete();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const cancelItem = () => {
      editableItem.value = { ...props.item };
      isEditing.value = false;
    };

    const confirmDelete = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDeliveryChallanItem(props.item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your DeliveryChallan Item has been deleted.",
                "success"
              );
              if (props.onApiComplete) {
                props.onApiComplete();
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    };

    return {
      isEditing,
      editableItem,
      editItem,
      saveItem,
      confirmDelete,
      delivery_challan_id,
      designData,
      cancelItem,
    };
  },
});
